<template>
  <headGoBack :iCustomizdde="true">
    <template #default>
      <span> {{ $fanyi("国际物流") }} </span>
    </template>
  </headGoBack>
  <div class="additionalServiceFeePage">
    <!-- banner部分 -->
    <div class="banner">
      <div class="title">国 際 送 料 詳 細</div>
      <div class="middle">私たちは、中国からの輸入物流の管理と実行を担当し</div>
      <div class="bottom">
        Rakumartを利用して中国からの商品の購入、輸入の方法を根本的に変え、ビジネスに時間短縮とコストダンする様々な配送方法を提供させていただきます。
      </div>
    </div>

    <!-- 物流费用预估部分 -->
    <div class="preFee">
      <div class="paddingBox">
        <div class="feeTitle">見積ツール</div>
        <div class="feeContent">
          <button class="addButton" @click="addBox">箱を追加する</button>
          <div class="inputFieldList">
            <div
              v-for="(item, index) in boxList"
              :key="index"
              class="inputFieldItemBox"
            >
              <div class="inputFieldItem">
                <div class="inputBox">
                  <div class="inputTitle">長</div>
                  <div class="inputContainer">
                    <input
                      v-model.number="item.length"
                      placeholder="長"
                      type="number"
                      @change="isNull"
                    />
                    <div class="inputText">cm</div>
                  </div>
                </div>
                <div class="inputBox">
                  <div class="inputTitle">幅</div>
                  <div class="inputContainer">
                    <input
                      v-model.number="item.width"
                      placeholder="幅"
                      type="number"
                      @change="isNull"
                    />
                    <div class="inputText">cm</div>
                  </div>
                </div>
                <div class="inputBox">
                  <div class="inputTitle">縦</div>
                  <div class="inputContainer">
                    <input
                      v-model.number="item.height"
                      placeholder="縦"
                      type="number"
                      @change="isNull"
                    />
                    <div class="inputText">cm</div>
                  </div>
                </div>
                <div class="inputBox">
                  <div class="inputTitle">実際重量</div>
                  <div class="inputContainer">
                    <input
                      v-model.number="item.kg"
                      placeholder="実際重量"
                      type="number"
                      @change="isNull"
                    />
                    <div class="inputText">kg</div>
                  </div>
                </div>
                <div class="inputBox">
                  <div class="inputTitle">箱数</div>
                  <div class="inputContainer">
                    <input
                      v-model.number="item.number"
                      placeholder="箱数"
                      type="number"
                      @change="isNull"
                    />
                  </div>
                </div>
                <div class="inputBox">
                  <div class="inputTitle">合計重量</div>
                  <div class="inputContainer">
                    <!-- safari会对disabled的输入框加上透明效果,所以需要加深颜色 -->
                    <input
                      v-model.number="item.totalWeight"
                      :class="{ disabled: equipmentType == 'IOS' }"
                      disabled
                    />
                    <div class="inputText">kg</div>
                  </div>
                </div>
              </div>
              <div class="dip deleteButtonBox">
                <button class="deleteButton" @click="deleteBox(index)">
                  削除
                </button>
              </div>
            </div>
          </div>
          <div class="bottomStatisticsBox jsb acenter">
            <div>合計</div>
            <div class="widthFont">
              {{ totalWeight }}
            </div>
          </div>
        </div>
      </div>

      <div class="internationalLogisticsFreightListContainerScrollBox">
        <div class="internationalLogisticsFreightListContainer">
          <div class="internationalLogisticsFreightHeader">
            <div class="tli flex200">発送方法</div>
            <div class="tli flex160">種類</div>
            <div class="tli flex160">決済重量</div>
            <div class="tli flex200">国際送料（元）</div>
            <div class="tli flex200">国際送料（円）</div>
            <div class="tli flex230">納期</div>
            <div class="tli flex100">操作</div>
          </div>
          <div class="internationalLogisticsFreightFooter">
            <div
              v-for="(item, index) in logisticsAmountAllTable"
              :key="index"
              class="internationalLogisticsFreightContainer"
            >
              <!-- 発送方法 -->
              <div class="tli flex200">
                {{ item.logistics_name }}
              </div>
              <!-- 種類 -->
              <div class="tli flex160">
                <div
                  :style="
                    airTransportationArr.indexOf(item.logistics_name) == -1
                      ? 'background: linear-gradient(0deg, #5774CC, #678AF3);'
                      : 'background: linear-gradient(0deg, #C64B42, #E76E65);'
                  "
                  class="transportType"
                >
                  {{
                    airTransportationArr.indexOf(item.logistics_name) == -1
                      ? "船便"
                      : "航空便"
                  }}
                </div>
              </div>
              <!-- 決済重量 -->
              <div class="tli flex160">
                {{ item.charged_weight }}
              </div>
              <!-- 国際送料（元） -->
              <div class="tli flex200">
                {{ item.total_money_rmb }}
              </div>
              <!-- 国際送料（円） -->
              <div class="tli flex200">
                {{
                  $fun.JPYNumSegmentation(
                    Number(
                      Number(item.total_money_rmb) *
                        Number($store.state.exchangeRate)
                    ).toFixed(0)
                  )
                }}
              </div>
              <!-- 納期 -->
              <div class="tli flex230">
                <div v-html="item.sign_receive_days"></div>
              </div>
              <!-- 操作 -->
              <div class="tli flex100">
                <div
                  class="goAuchorBtn"
                  @click="
                    goAuchor(
                      'internationalLogisticsManner' + item.logistics_name
                    );
                    catalogueIndex = index;
                  "
                >
                  詳細へ
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 物流比较表格 -->
    <div class="expressTable" v-if="false">
      <div class="tableTitle">国際送料　簡易比較</div>
      <div class="expressTablescrollBox">
        <div class="mytable">
          <div class="tul ttt">
            <div class="tli ttt lefttitle"></div>
            <div class="redTitleBox titleBox">
              <div class="oneRow">航空便</div>
              <div class="twoRow">
                <div class="tli ttt">EMS</div>
                <div class="tli ttt">OCS</div>
                <div class="tli ttt">KS-JP</div>
                <div class="tli ttt">電子特別便</div>
                <div class="tli ttt">DHL</div>
              </div>
            </div>
            <div class="blueTitleBox titleBox">
              <div class="oneRow">船便</div>
              <div class="twoRow">
                <div class="tli ttt">EMS船便</div>
                <div class="tli ttt">DQ船便</div>
                <div class="tli ttt">TW船便</div>
                <div class="tli ttt">RW船便</div>
                <div class="tli ttt">FBA新幹線</div>
                <div class="tli ttt">コンテナ</div>
              </div>
            </div>
          </div>
          <div class="tul tbo">
            <div class="tli tbo lefttitle top">FBA納品</div>
            <div class="tli tbo">X</div>
            <div class="tli tbo">O</div>
            <div class="tli tbo">O</div>
            <div class="tli tbo">O</div>
            <div class="tli tbo">O</div>
            <div class="tli tbo">X</div>
            <div class="tli tbo">O</div>
            <div class="tli tbo">O</div>
            <div class="tli tbo">O</div>
            <div class="tli tbo">O</div>
            <div class="tli tbo">O</div>
          </div>
          <div class="tul tbo">
            <div class="tli tbo lefttitle">電池・磁石</div>
            <div class="tli tbo">X</div>
            <div class="tli tbo">X</div>
            <div class="tli tbo">X</div>
            <div class="tli tbo">O</div>
            <div class="tli tbo">X</div>
            <div class="tli tbo">O</div>
            <div class="tli tbo">O</div>
            <div class="tli tbo">磁石〇電池×</div>
            <div class="tli tbo">磁石〇電池×</div>
            <div class="tli tbo">O</div>
            <div class="tli tbo">O</div>
          </div>
          <div class="tul tbo">
            <div class="tli tbo lefttitle">液体含む</div>
            <div class="tli tbo">X</div>
            <div class="tli tbo">X</div>
            <div class="tli tbo">X</div>
            <div class="tli tbo">X</div>
            <div class="tli tbo">X</div>
            <div class="tli tbo">O</div>
            <div class="tli tbo">X</div>
            <div class="tli tbo">X</div>
            <div class="tli tbo">X</div>
            <div class="tli tbo">X</div>
            <div class="tli tbo">O</div>
          </div>
          <div class="tul tbo">
            <div class="tli tbo lefttitle">重量制限</div>
            <div class="tli tbo">～30KG/箱</div>
            <div class="tli tbo">無制限</div>
            <div class="tli tbo">無制限</div>
            <div class="tli tbo">～30KG/箱</div>
            <div class="tli tbo">～30KG/箱</div>
            <div class="tli tbo">～30KG/箱</div>
            <div class="tli tbo">無制限</div>
            <div class="tli tbo">無制限</div>
            <div class="tli tbo">無制限</div>
            <div class="tli tbo">無制限</div>
            <div class="tli tbo">/</div>
          </div>
          <div class="tul tbo">
            <div class="tli tbo lefttitle">同梱制限</div>
            <div class="tli tbo">無</div>
            <div class="tli tbo">無</div>
            <div class="tli tbo">無</div>
            <div class="tli tbo">無</div>
            <div class="tli tbo">無</div>
            <div class="tli tbo">無</div>
            <div class="tli tbo">無</div>
            <div class="tli tbo">無</div>
            <div class="tli tbo">無</div>
            <div class="tli tbo">無</div>
            <div class="tli tbo">なし</div>
          </div>
          <div class="tul tbo">
            <div class="tli tbo lefttitle">納期(目安)</div>
            <div class="tli tbo">4日～</div>
            <div class="tli tbo">3日～</div>
            <div class="tli tbo">3日～</div>
            <div class="tli tbo">5日～</div>
            <div class="tli tbo">2～3日</div>
            <div class="tli tbo">3週間～</div>
            <div class="tli tbo">9日～</div>
            <div class="tli tbo">9日～</div>
            <div class="tli tbo">9日～12日</div>
            <div class="tli tbo">15日～18日</div>
            <div class="tli tbo">10日～</div>
          </div>
          <div class="tul tbo">
            <div class="tli tbo lefttitle">配送会社</div>
            <div class="tli tbo">日本郵便</div>
            <div class="tli tbo">佐川急便</div>
            <div class="tli tbo">佐川急便</div>
            <div class="tli tbo">佐川急便</div>
            <div class="tli tbo">自社配送・その他</div>
            <div class="tli tbo">日本郵便</div>
            <div class="tli tbo">佐川急便・その他</div>
            <div class="tli tbo">佐川急便・その他</div>
            <div class="tli tbo">佐川急便・その他</div>
            <div class="tli tbo">佐川急便・その他</div>
            <div class="tli tbo">/</div>
          </div>
          <div class="tul tbo">
            <div class="tli tbo lefttitle">容積換算値</div>
            <div class="tli tbo">6000</div>
            <div class="tli tbo">6000</div>
            <div class="tli tbo">6000</div>
            <div class="tli tbo">6000</div>
            <div class="tli tbo">5000</div>
            <div class="tli tbo">6000</div>
            <div class="tli tbo">6000</div>
            <div class="tli tbo">6000</div>
            <div class="tli tbo">6000</div>
            <div class="tli tbo">6000</div>
            <div class="tli tbo">/</div>
          </div>
          <div class="tul tbo">
            <div class="tli tbo lefttitle">最安価格</div>
            <div class="tli tbo">下記参照</div>
            <div class="tli tbo">17元/KG</div>
            <div class="tli tbo">16元/KG</div>
            <div class="tli tbo">33元/KG</div>
            <div class="tli tbo">/</div>
            <div class="tli tbo">下記参照</div>
            <div class="tli tbo">6.5元/KG</div>
            <div class="tli tbo">6.5元/KG</div>
            <div class="tli tbo">6.3元/KG</div>
            <div class="tli tbo">1450/m³</div>
            <div class="tli tbo">/</div>
          </div>
          <div class="tul tbo">
            <div class="tli tbo lefttitle bottom">備考</div>
            <div class="tli tbo">
              月曜日～金曜日のみ運行<br />
              箱当たり4元の通関手数料が別途請求（中国側）
            </div>
            <div class="tli tbo">
              沖縄 33kg以下＋100元、<br />33kg以上 +3元/kg
            </div>
            <div class="tli tbo">
              沖縄・北海道・鹿児島<br />遠隔地費用あり<br />通関手数料
              1500円/回<br />（税込）
            </div>
            <div class="tli tbo">沖縄・北海道・鹿児島<br />遠隔地費用あり</div>
            <div class="tli tbo">離島地域は別途追加費用があり</div>
            <div class="tli tbo">
              箱あたり4元の通関手数料が別途請求（中国側）
            </div>
            <div class="tli tbo">週3回の集荷発送、遠隔地費用あり</div>
            <div class="tli tbo">週3回の集荷発送、遠隔地費用あり</div>
            <div class="tli tbo">
              定額会員限定利用、週3回の集荷発送、遠隔地費用あり
            </div>
            <div class="tli tbo">AmazonFBA納品専用、週2回の集荷発送</div>
            <div class="tli tbo">/</div>
          </div>
        </div>
      </div>

      <div class="explain">
        ※運賃は下記の送料表をご覧の上、配送方法をお選び下さい。<br />※各運送会社の追跡サイトは弊社トップページに記載されます。<br />※最安価格は発送重量によって変わりますので、あくまで参考（最安値）となります。<br />※その他、コンテナ混載便や貸切コンテナ便もあります。詳しく下記でご確認下さい。<br />※現在基本日本向けの配送になります、その他の国へ配送されたい場合は個別で担当者まで問い合わせください。
      </div>
      <div class="attention">
        <div class="header">商品発送の注意事項</div>
        <div class="attentionText">
          ※特に指定が無い場合は、20kg以上はOCSにて発送させて頂きます。<br />※全配送会社、実際重量と容積換算どちらのか重い方の計算となります。<br />※容積換算重量算出の場合は、いずれも計算値6000で計算致します。（高速船便、FedEx　5000　OCS
          6000で計算）容積換算式：段ボール箱外寸（60cm×40cm×50cm）、容積重量は（60×40×50）÷定数（6000の場合）＝20KGになります。<br />※関税、輸入消費税が発生する場合があります。金額は日本税関を通過するまで解りません。関税が発生した場合、着払い又は後日請求になります。
        </div>
      </div>
    </div>
    <!-- 物流收费说明 -->
    <div class="expressFee">
      <div class="expressFeeTitle">詳細</div>
      <div class="expressContainer">
        <div
          v-for="(item, index) in expressContainerTable"
          :key="index"
          class="expressContainerTable"
          :id="'internationalLogisticsManner' + item.name"
        >
          <div class="expressContainerTableTop flexAndCenter">
            <div>RAKUMART - JP</div>
            <div>{{ index < 10 ? "0" + (index + 1) : index + 1 }}</div>
          </div>
          <div class="expressName">{{ item.name }}</div>
          <div class="tableDataClass">
            <div v-html="item.tableData"></div>
          </div>
          <div class="expressAttentionText">注意事項</div>
          <!-- 注意事项 -->
          <div class="expressAttentionItem">
            <div v-html="item.useful_rule?.note_jp"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 欧洲国际物流送达时间表 -->
    <div class="ouzhou">
      <div class="tableTitle">アメリカへ発送</div>
      <div class="expressTablescrollBox">
        <div class="mytable">
          <div class="tul ttt">
            <div class="tli ttt lefttitle"></div>
            <div class="redTitleBox titleBox">
              <div class="oneRow">最低発送重量</div>
            </div>
            <div class="blueTitleBox titleBox">
              <div class="oneRow">納期（目安）</div>
            </div>
          </div>
          <div class="tul tbo">
            <div class="tli tbo lefttitle top">航空便</div>
            <div class="tli tbo">なし</div>
            <div class="tli tbo">10～15日</div>
          </div>
          <div class="tul tbo">
            <div class="tli tbo lefttitle">トラック</div>
            <div class="tli tbo">21キロ</div>
            <div class="tli tbo">25～30日</div>
          </div>
          <div class="tul tbo">
            <div class="tli tbo lefttitle">船便</div>
            <div class="tli tbo">1㎥</div>
            <div class="tli tbo">45～50日</div>
          </div>
          <div class="tul tbo">
            <div class="tli tbo lefttitle">鉄道</div>
            <div class="tli tbo">21キロ</div>
            <div class="tli tbo">50～60日</div>
          </div>
        </div>
      </div>
      <div class="timeBox">※ 最終更新日: 2023.10.16</div>
      <div class="explain">
        <div class="header">注意項目</div>
        ヨーロッパ各国への発送について<br />
        国別で納期や金額が違うため、<br />
        ご利用を希望の際は発送予定の商品内容を担当者へ共有していただければ、<br />
        個別で見積させていただきます。
      </div>
    </div>

    <!-- 欧洲国际物流送达时间表 -->
    <div class="ouzhou">
      <div class="tableTitle">アメリカへ発送</div>
      <div class="expressTablescrollBox">
        <div class="mytable">
          <div class="tul ttt">
            <div class="tli ttt lefttitle"></div>
            <div class="redTitleBox titleBox">
              <div class="oneRow">最低発送重量</div>
            </div>
            <div class="blueTitleBox titleBox">
              <div class="oneRow">納期（目安）</div>
            </div>
          </div>
          <div class="tul tbo">
            <div class="tli tbo lefttitle top">航空便</div>
            <div class="tli tbo">12キロ</div>
            <div class="tli tbo">9～15日</div>
          </div>
          <div class="tul tbo">
            <div class="tli tbo lefttitle">マトソン</div>
            <div class="tli tbo">21キロ</div>
            <div class="tli tbo">23～30日</div>
          </div>
          <div class="tul tbo">
            <div class="tli tbo lefttitle">FEDEX</div>
            <div class="tli tbo">0.5キロ</div>
            <div class="tli tbo">7～10日</div>
          </div>
        </div>
      </div>
      <div class="timeBox">※ 最終更新日: 2023.11.3</div>
      <div class="explain">
        <div class="header">注意項目</div>
        アメリカへの発送について<br />
        地域（東部、中部、西部）や商品の種類によって、国際運賃と納期が異なりますので、<br />
        ご利用を希望の際は発送予定の商品内容を担当者へ共有していただければ、<br />
        個別で見積させていただきます。
      </div>
    </div>
  </div>
  <footBar />
</template>
<script setup>
import headGoBack from "@/components/headGoBack";
import footBar from "@/components/footBar/index.vue";
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
// 预算重量
const preWeight = ref("0");
// 预算体积
const preVolumn = ref("0");
// 物流纳期表格
const expressArr = ref([]);

// 物流收费表格
const expressContainerTable = ref([]);
// 箱子列表
const boxList = ref([
  {
    kg: 0,
    length: 58,
    width: 51,
    height: 50,
    number: 1,
    totalWeight: 0,
    volume: 147900,
  },
]);
// 总计重量
const totalWeight = ref(0);
// 物流预估列表
const logisticsAmountAllTable = ref([]);
const airTransportationArr = ref([
  "EMS",
  "OCS",
  "KS-JP",
  "電子特別便",
  "DHL",
  "Raku-KS",
  "RAKU-KS",
  "KS-JP電子特殊便",
  "KS-JP航空便",
  "電子特殊便",
]);

const equipmentType = proxy.$fun.judgeClient();
// ---------------------------methods-------------------------------------

const getData = () => {
  proxy.$api.logisticsTableData().then((res) => {
    ////console.log('事件名',res)
    if (res.code != 0) return proxy.$message.error(proxy.$fanyi(res.data.msg));
    //接下来的操作
    // console.log(res.data,'express');
    expressContainerTable.value = res.data;
    expressArr.value = res.data;
  });
};
getData();
const isNull = () => {
  boxList.value.forEach((item) => {
    if (item.length != 0 && item.width != 0 && item.height != 0) {
      volumeCalculate();
      getLogisticsAmountAll();
    }
    if (item.number < 1) {
      item.number = 1;
    } else if (item.number > 10000) {
      item.number = 10000;
    }
  });
};

const addBox = () => {
  boxList.value.push({
    kg: 0,
    length: 58,
    width: 51,
    height: 50,
    number: 1,
    totalWeight: 0,
    volume: 147900,
  });
  volumeCalculate();
  getLogisticsAmountAll();
};

// 计算面积
const volumeCalculate = () => {
  totalWeight.value = 0;
  boxList.value.forEach((item) => {
    if (item.length != 0 && item.width != 0 && item.height != 0) {
      let length = item.length;
      let width = item.width;
      let height = item.height;
      item.volume = length * width * height;
    }
    if (item.weight != 0) {
      item.totalWeight = item.kg * item.number;
      totalWeight.value += Number(item.kg * item.number);
    }
  });
};

const deleteBox = (index) => {
  boxList.value.splice(index, 1);
  if (boxList.value.length === 0) {
    boxList.value.push({
      kg: 0,
      length: 58,
      width: 51,
      height: 50,
      number: 1,
      totalWeight: 0,
      volume: 147900,
    });
  }
  volumeCalculate();
  getLogisticsAmountAll();
};

// 获取物流预估列表
const getLogisticsAmountAll = () => {
  // logisticsAmountAllTable.value = [];
  const list = [];
  let boxList_copy = JSON.parse(JSON.stringify(boxList.value));
  boxList_copy.forEach((item) => {
    item.weight = item.kg;
    let num = item.number;
    delete item.kg;
    delete item.number;
    delete item.totalWeight;
    for (let i = 0; i < num; i++) {
      list.push(item);
    }
  });
  proxy.$api.getLogisticsAmountAll({ list }).then((res) => {
    if (res.code != 0) {
      logisticsAmountAllTable.value = [];
      return proxy.$message.error(proxy.$fanyi(res.data.msg));
    }

    logisticsAmountAllTable.value = res.data;
  });
};

// 跳转到指定物流位置
const goAuchor = (id) => {
  // const ids = "#" + id;
  // document.querySelector(ids).scrollIntoView(true);
  // const anchor = proxy.$el.querySelector(ids);
  // window.scrollTo({
  //   top: anchor.offsetTop - 100,
  //   behavior: "smooth",
  // });
  console.log(id);

  let element = document.getElementById(id);
  element.scrollIntoView({
    block: "center", //垂直滚动对齐方式
    behavior: "smooth", //是否使用过渡动画
  });
};
//------------------------ pageLoad -------------------------------------
getLogisticsAmountAll();
defineExpose({});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.tableFont {
  font-size: 24px;
  line-height: 1.4;
}
.additionalServiceFeePage {
  // border: solid;
  background-color: white;
  overflow: hidden;
  .banner {
    width: 750px;
    height: 450px;
    padding: 102px 30px 99px 30px;
    background-image: url("../../../assets/foot-img/interExpress/banner.jpg");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    .title {
      font-size: 36px;
      font-weight: 600;
      color: #ffffff;
    }
    .middle {
      font-size: 28px;
      font-weight: 400;
      color: #ffffff;
      line-height: 32px;
      margin: 60px 0 30px 0;
    }
    .bottom {
      font-size: 28px;
      font-weight: 400;
      color: #ffffff;
      line-height: 32px;
    }
  }

  .preFee {
    background-color: #f6f6f6;

    padding-bottom: 60px;
    .paddingBox {
      padding: 0 30px;
    }
    .feeTitle {
      padding: 38px 0 32px;
      line-height: 64px;
      font-size: 30px;
      font-weight: 600;
      color: #333333;
      text-align: center;
    }
    .feeContent {
      background-color: white;
      margin-bottom: 30px;
      padding: 30px;
      .addButton {
        @extend .dip;
        padding: 10px 20px;
        min-height: 56px;
        background: #50c136;
        border-radius: 6px;
        font-weight: 500;
        font-size: 24px;
        color: #ffffff;
        margin-bottom: 30px;
      }

      .inputFieldList {
        .inputFieldItemBox {
          margin-bottom: 30px;
          border-bottom: #f6f6f6 solid 1px;
          .inputFieldItem {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            margin-bottom: 30px;

            .inputBox {
              width: 200px;
              margin-top: 15px;
              &:nth-child(-n + 3) {
                margin-top: 0;
              }
              .inputTitle {
                font-weight: bold;
                font-size: 24px;
                color: #333333;
                margin-bottom: 20px;
                line-height: 36px;
              }
              .inputContainer {
                position: relative;
                input {
                  border-radius: 4px;
                  width: 200px;
                  height: 70px;
                  border: 1px solid #dfdfdf;
                  border: 1px solid #dfdfdf;
                  outline: none;
                  padding: 0 15px;
                  font-size: 24px;

                  &:disabled {
                    color: #dfdfdf;
                    border-color: #dfdfdf;
                    background: white;
                  }
                  &.disabled {
                    color: #838383;
                    border-color: #838383;
                  }
                  &:focus {
                    border: 1px solid #b4272b;
                    border: 1px solid #b4272b;
                  }
                }
                .inputText {
                  width: 64px;
                  height: 40px;
                  @extend .dip;
                  border-left: 1px solid #dfdfdf;
                  border-left: 1px solid #dfdfdf;
                  font-size: 28px;
                  font-weight: bold;
                  color: #333;
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }
          }
          .deleteButtonBox {
            margin-bottom: 30px;
            .deleteButton {
              @extend .dip;
              margin: 0 !important;
              height: 56px;
              background: #b4272d;
              border-radius: 6px;
              font-weight: 500;
              font-size: 24px;
              color: #ffffff;
            }
          }
        }
      }

      .bottomStatisticsBox {
        width: 100%;
        height: 76px;
        background: #f6f6f6;
        padding: 0 0 0 30px;

        div {
          font-size: 24px;
          font-weight: bold;
          color: #333333;
          &:last-child {
            &::after {
              content: " kg";
            }
          }
        }
        .widthFont {
          width: 200px;
        }
      }
    }

    .internationalLogisticsFreightListContainerScrollBox {
      background-color: #f6f6f6;
      width: 750px;
      overflow: scroll;
      font-size: 24px;
      line-height: 1.4;
      padding: 0 30px;

      .internationalLogisticsFreightListContainer {
        width: max-content;
        padding: 30px;
        background: #ffffff;
        border-radius: 6px;

        .flex230 {
          flex: 0 0 230px;
        }
        .flex200 {
          flex: 0 0 200px;
        }
        .flex160 {
          flex: 0 0 160px;
        }
        .flex100 {
          flex: 0 0 100px;
        }
        .internationalLogisticsFreightHeader {
          // height: 70px;
          background: #f6f6f6;
          border-radius: 6px;
          padding-left: 24px;
          display: flex;
          align-items: center;

          div {
            color: #333333;
            padding: 25px 0;
            font-weight: bold;
            line-height: 1;
          }
        }

        .internationalLogisticsFreightFooter {
          .internationalLogisticsFreightContainer {
            min-height: 90px;
            padding-left: 24px;
            border-bottom: 1px solid #dfdfdf;
            display: flex;
            align-items: center;

            .tli {
              padding: 20px 20px 20px 0;
              @extend .tableFont;
              .transportType {
                width: 100px;
                height: 50px;
                border-radius: 6px;
                color: #ffffff;
                @extend .dip;
              }

              .goAuchorBtn {
                color: #b4272b;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

  .expressTable {
    padding-top: 60px;
    .tableTitle {
      font-size: 30px;
      font-weight: 600;
      color: #333333;
      text-align: center;
      margin-bottom: 42px;
    }
    .expressTablescrollBox {
      width: 100%;
      overflow: scroll;
      padding: 0 30px;
      .mytable {
        width: max-content;
        // * {
        //   box-sizing: border-box;
        // }

        .titleBox {
          display: flex;
          flex: 1;
          flex-direction: column;
          .oneRow {
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 26px;
            font-weight: 600;
            padding: 20px 0 5px;
          }
          .twoRow {
            width: max-content;
            display: flex;
            width: 100%;
          }
        }
        .titleBox.redTitleBox {
          border-radius: 10px 0px 0px 0px;
          background: linear-gradient(0deg, #c64b42, #e76e65);
        }
        .titleBox.blueTitleBox {
          border-radius: 0px 10px 0px 0px;
          background: linear-gradient(0deg, #5774cc, #678af3);
        }
        > .tul {
          display: flex;
          width: 1960px;
          // 表头
          &.ttt {
            // width: max-content;
          }
          // 内容
          &.tbo {
            &:nth-child(2n + 1) {
              background: #f6f6f6;
            }
          }

          // 单个单元格
          .tli {
            flex: 1;
            font-size: 24px;
            line-height: 1.5;
            min-height: 70px;
            padding: 10px;
            &.ttt {
              border: none;
              display: flex;
              min-height: 50px;
              justify-content: center;
              align-items: center;
              color: white;
            }
            &.tbo {
              display: flex;
              justify-content: center;
              align-items: center;
              //如果把padding单独设在tbo里而ttt没有的话会单独设置某一列flex:1.X时会宽度不一致
              padding-top: 10px;
              padding-bottom: 10px;
              border-bottom: 1px solid #dfdfdf;
            }
          }
          .tli.lefttitle {
            flex: 0 0 160px;
            &.ttt {
            }
            &.tbo {
              background: #f0f0f0;
              padding: 0;
            }
            &.top {
              border-radius: 10px 0 0 0;
            }
            &.bottom {
              border-radius: 0 0 0 10px;
            }
          }
        }
      }
    }

    .explain {
      line-height: 30px;
      font-size: 24px;
      font-weight: 400;
      color: #b4272b;
      margin: 42px 30px;
    }
    .attention {
      padding: 0 30px;
      margin-bottom: 59px;
      .header {
        font-size: 28px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 17px;
      }
      .attentionText {
        line-height: 30px;
        font-size: 24px;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .ouzhou {
    padding-top: 60px;
    .tableTitle {
      font-size: 30px;
      line-height: 1;
      font-weight: 600;
      text-align: center;
      margin-bottom: 42px;
    }
    .expressTablescrollBox {
      width: 100%;
      overflow: scroll;
      padding: 0 30px;
      .mytable {
        width: max-content;
        // * {
        //   box-sizing: border-box;
        // }

        .titleBox {
          display: flex;
          flex: 1;
          flex-direction: column;
          .oneRow {
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 26px;
            font-weight: 600;
            padding: 25px 35px;
          }
        }
        .titleBox.redTitleBox {
          border-radius: 10px 0px 0px 0px;
          background: linear-gradient(0deg, #c64b42, #e76e65);
        }
        .titleBox.blueTitleBox {
          border-radius: 0px 10px 0px 0px;
          background: linear-gradient(0deg, #5774cc, #678af3);
        }
        > .tul {
          display: flex;
          width: 690px;
          // 表头
          &.ttt {
            // width: max-content;
          }
          // 内容
          &.tbo {
            &:nth-child(2n + 1) {
              background: #f6f6f6;
            }
          }

          // 单个单元格
          .tli {
            flex: 1;
            font-size: 24px;
            line-height: 1.5;
            min-height: 70px;
            padding: 10px;
            &.ttt {
              border: none;
              display: flex;
              min-height: 50px;
              justify-content: center;
              align-items: center;
              color: white;
            }
            &.tbo {
              display: flex;
              justify-content: center;
              align-items: center;
              //如果把padding单独设在tbo里而ttt没有的话会单独设置某一列flex:1.X时会宽度不一致
              padding-top: 10px;
              padding-bottom: 10px;
              border-bottom: 1px solid #dfdfdf;
            }
          }
          .tli.lefttitle {
            flex: 0 0 160px;

            &.ttt {
            }
            &.tbo {
              background: #f0f0f0;
              padding: 0;
            }
            &.top {
              border-radius: 10px 0 0 0;
            }
            &.bottom {
              border-radius: 0 0 0 10px;
            }
          }
        }
      }
    }

    .explain {
      line-height: 30px;
      font-size: 24px;
      font-weight: 400;
      color: #b4272b;
      margin: 42px 30px;
      .header {
        font-size: 28px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 17px;
      }
    }
    .attention {
      padding: 0 30px;
      margin-bottom: 59px;
      .header {
        font-size: 28px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 17px;
      }
      .attentionText {
        line-height: 30px;
        font-size: 24px;
        font-weight: 400;
        color: #333333;
      }
    }
    .timeBox {
      margin: 10px 30px 0;
      color: #b4272b;
      font-size: 20px;
      font-weight: 700;
      text-align: right;
    }
  }
  .expressFee {
    padding: 60px 30px;
    background: #f6f6f6;
    .expressFeeTitle {
      text-align: center;
      font-size: 30px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 42px;
    }
    .expressContainer {
      width: 100%;
      margin: 0 auto;

      .expressContainerTable {
        .tableDataClass {
          overflow-x: auto;
          max-width: 100%;
          /deep/ div > table {
            // width: 100%;
            white-space: nowrap;
            border: 2px solid #c40622;
            margin-bottom: 20px;
            width: min-content;
            tr > td:first-child {
              width: 148px;
              width: 148px;
              height: 70px;
              background: #b4272b;
              line-height: 70px;
              text-align: center;
              color: #fefefe;
              font-weight: bold;
              font-size: 24px;
              border-bottom: 1px solid #dfdfdf;
            }

            tr:first-child > td {
              border-right: 1px solid #dfdfdf;
              *border-bottom: 1px solid #dfdfdf;
            }

            tr:last-child > td {
              border-right: 1px solid #dfdfdf;
              border-bottom: 2px solid #b4272b;
            }

            tr > td {
              width: 148px;
              width: 148px;
              height: 70px;
              font-size: 24px;
              background: #fff;
              line-height: 70px;
              text-align: center;
              color: #333333;
              border-bottom: 1px solid #dfdfdf;
            }
          }
        }

        border-top: 1px solid #dfdfdf;
        padding-bottom: 80px;

        .expressContainerTableTop {
          display: flex;
          margin-top: 8px;
          justify-content: space-between;

          div {
            font-size: 20px;
            font-weight: 600;
            font-style: italic;
            color: #dfdfdf;
            line-height: 24px;
          }

          div:last-child {
            font-style: italic;
          }
        }

        .expressName {
          margin: 30px 0 20px;
          font-size: 28px;
          font-weight: 600;
          color: #333333;
        }
        .expressFeeDate {
          margin-bottom: 18px;
        }
        .expressFeeDate,
        .updateDay {
          font-size: 24px;
          font-weight: 600;
          color: #b4272b;
        }
        .expressAttentionText {
          margin: 56px 0 17px;
          color: #333333;
          font-weight: bold;
          font-size: 28px;
        }

        .expressAttentionItem {
          font-size: 24px;
          font-weight: 400;
          line-height: 1.4;
          color: #333333;
          :deep()img {
            max-width: 690px;
          }
          :deep() p {
            max-width: 750px;
            overflow: auto;
          }
        }
      }
    }
  }
}
</style>
